import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineDashboard } from "react-icons/md";
import { LuBox } from "react-icons/lu";
import { FaRegPlusSquare } from "react-icons/fa";
import { FiClipboard } from "react-icons/fi";
import { FiDatabase } from "react-icons/fi";
import { itemContext } from "../pages/itemContext";
import { useNavigate } from "react-router-dom";
import { FiFilter } from "react-icons/fi";
import { RiHistoryFill } from "react-icons/ri";
import { CgSmartHomeWashMachine } from "react-icons/cg";

const Sidebar = () => {
  const { toggleMenu, setToggleMenu } = useContext(itemContext);
  const location = useLocation();
  const navigate = useNavigate();
  let pathname = location.pathname;
  const navigation = (to) => {
    setToggleMenu(false);
    navigate(to);
  };
  return (
    <>
      {/* Sidebar */}
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="submenu-open">
                {/* <h6 className="submenu-hdr">Main</h6> */}
                <ul>
                  <li className={pathname === "/" ? "active" : ""}>
                    <a type="button" onClick={() => navigation("/")}>
                      <MdOutlineDashboard />
                      <span>Dashboard</span>
                    </a>
                  </li>
                  <li className={pathname === "/products" ? "active" : ""}>
                    <a type="button" onClick={() => navigation("/products")}>
                      <LuBox />
                      <span>Products</span>
                    </a>
                  </li>
                  <li className={pathname === "/add-product" ? "active" : ""}>
                    <a type="button" onClick={() => navigation("/add-product")}>
                      <FaRegPlusSquare />
                      <span>Add Product</span>
                    </a>
                  </li>
                  <li className={pathname === "/category" ? "active" : ""}>
                    <a type="button" onClick={() => navigation("/category")}>
                      <FiDatabase />
                      <span>Category</span>
                    </a>
                  </li>
                  <li className={pathname === "/add-category" ? "active" : ""}>
                    <a type="button" onClick={() => navigation("/add-category")}>
                      <FaRegPlusSquare />
                      <span>Add Category</span>
                    </a>
                  </li>
                  <li className={pathname === "/order" ? "active" : ""}>
                    <a type="button" onClick={() => navigation("/order")}>
                      <FiClipboard />
                      <span>Order</span>
                    </a>
                  </li>
                  <li className={pathname === "/filter-order" ? "active" : ""}>
                    <a type="button" onClick={() => navigation("/filter-order")}>
                      <FiFilter />
                      <span>Filter Order</span>
                    </a>
                  </li>
                  <li className={pathname === "/create-order" ? "active" : ""}>
                    <a type="button" onClick={() => navigation("/create-order")}>
                      <FaRegPlusSquare />
                      <span>Create Order</span>
                    </a>
                  </li>
                  <li className={pathname === "/order-history" ? "active" : ""}>
                    <a type="button" onClick={() => navigation("/order-history")}>
                      <RiHistoryFill />
                      <span>Order History</span>
                    </a>
                  </li>
                  <li className={pathname === "/inwash" ? "active" : ""}>
                    <a type="button" onClick={() => navigation("/inwash")}>
                      <CgSmartHomeWashMachine />
                      <span>In Wash</span>
                    </a>
                  </li>
                  {/* <li className="submenu">
                    <a type="button">
                      <i data-feather="smartphone" />
                      <span>Application</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/chat.html">
                          Chat
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/calendar.html">
                          Calendar
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/email.html">
                          Email
                        </a>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </li>
              {/* <li className="submenu-open">
                <h6 className="submenu-hdr">Products</h6>
                <ul>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/productlist.html">
                      <i data-feather="box" />
                      <span>Products</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/addproduct.html">
                      <i data-feather="plus-square" />
                      <span>Create Product</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/categorylist.html">
                      <i data-feather="codepen" />
                      <span>Category</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/brandlist.html">
                      <i data-feather="tag" />
                      <span>Brands</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/subcategorylist.html">
                      <i data-feather="speaker" />
                      <span>Sub Category</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/barcode.html">
                      <i data-feather="align-justify" />
                      <span>Print Barcode</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/importproduct.html">
                      <i data-feather="minimize-2" />
                      <span>Import Products</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="submenu-open">
                <h6 className="submenu-hdr">Sales</h6>
                <ul>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/saleslist.html">
                      <i data-feather="shopping-cart" />
                      <span>Sales</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/invoicereport.html">
                      <i data-feather="file-text" />
                      <span>Invoices</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/salesreturnlists.html">
                      <i data-feather="copy" />
                      <span>Sales Return</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/quotationList.html">
                      <i data-feather="save" />
                      <span>Quotation</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/pos.html">
                      <i data-feather="hard-drive" />
                      <span>POS</span>
                    </a>
                  </li>
                  <li className="submenu">
                    <a type="button">
                      <i data-feather="shuffle" />
                      <span>Transfer</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/transferlist.html">
                          Transfer List
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/importtransfer.html">
                          Import Transfer{" "}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <a type="button">
                      <i data-feather="corner-up-left" />
                      <span>Return</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/salesreturnlist.html">
                          Sales Return
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/purchasereturnlist.html">
                          Purchase Return
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="submenu-open">
                <h6 className="submenu-hdr">Purchases</h6>
                <ul>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/purchaselist.html">
                      <i data-feather="shopping-bag" />
                      <span>Purchases</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/importpurchase.html">
                      <i data-feather="minimize-2" />
                      <span>Import Purchases</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/purchaseorderreport.html">
                      <i data-feather="file-minus" />
                      <span>Purchase Order</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/purchasereturnlist.html">
                      <i data-feather="refresh-cw" />
                      <span>Purchase Return</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="submenu-open">
                <h6 className="submenu-hdr">Finance &amp; Accounts</h6>
                <ul>
                  <li className="submenu">
                    <a type="button">
                      <i data-feather="file-text" />
                      <span>Expense</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/expenselist.html">
                          Expenses
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/expensecategory.html">
                          Expense Category
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="submenu-open">
                <h6 className="submenu-hdr">Peoples</h6>
                <ul>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/customerlist.html">
                      <i data-feather="user" />
                      <span>Customers</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/supplierlist.html">
                      <i data-feather="users" />
                      <span>Suppliers</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/userlist.html">
                      <i data-feather="user-check" />
                      <span>Users</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/storelist.html">
                      <i data-feather="home" />
                      <span>Stores</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="submenu-open">
                <h6 className="submenu-hdr">Reports</h6>
                <ul>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/salesreport.html">
                      <i data-feather="bar-chart-2" />
                      <span>Sales Report</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/purchaseorderreport.html">
                      <i data-feather="pie-chart" />
                      <span>Purchase report</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/inventoryreport.html">
                      <i data-feather="credit-card" />
                      <span>Inventory Report</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/invoicereport.html">
                      <i data-feather="file" />
                      <span>Invoice Report</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/purchasereport.html">
                      <i data-feather="bar-chart" />
                      <span>Purchase Report</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/supplierreport.html">
                      <i data-feather="database" />
                      <span>Supplier Report</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/customerreport.html">
                      <i data-feather="pie-chart" />
                      <span>Customer Report</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="submenu-open">
                <h6 className="submenu-hdr">User Management</h6>
                <ul>
                  <li className="submenu">
                    <a type="button">
                      <i data-feather="users" />
                      <span>Manage Users</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/newuser.html">
                          New User{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/userlists.html">
                          Users List
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="submenu-open">
                <h6 className="submenu-hdr">Pages</h6>
                <ul>
                  <li className="submenu">
                    <a type="button">
                      <i data-feather="shield" />
                      <span>Authentication</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/signin.html">
                          Log in
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/signup.html">
                          Register
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/forgetpassword.html">
                          Forgot Password
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/resetpassword.html">
                          Reset Password
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <a type="button">
                      <i data-feather="file-minus" />
                      <span>Error Pages</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/error-404.html">
                          404 Error{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/error-500.html">
                          500 Error{" "}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <a type="button">
                      <i data-feather="map" />
                      <span>Places</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/countrieslist.html">
                          Countries
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/statelist.html">
                          States
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/blankpage.html">
                      <i data-feather="file" />
                      <span>Blank Page</span>{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/components.html">
                      <i data-feather="pen-tool" />
                      <span>Components</span>{" "}
                    </a>
                  </li>
                </ul>
              </li>
              <li className="submenu-open">
                <h6 className="submenu-hdr">UI Interface</h6>
                <ul>
                  <li className="submenu">
                    <a type="button">
                      <i data-feather="layers" />
                      <span>Elements</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/sweetalerts.html">
                          Sweet Alerts
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/tooltip.html">
                          Tooltip
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/popover.html">
                          Popover
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/ribbon.html">
                          Ribbon
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/clipboard.html">
                          Clipboard
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/drag-drop.html">
                          Drag &amp; Drop
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/rangeslider.html">
                          Range Slider
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/rating.html">
                          Rating
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/toastr.html">
                          Toastr
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/text-editor.html">
                          Text Editor
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/counter.html">
                          Counter
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/scrollbar.html">
                          Scrollbar
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/spinner.html">
                          Spinner
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/notification.html">
                          Notification
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/lightbox.html">
                          Lightbox
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/stickynote.html">
                          Sticky Note
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/timeline.html">
                          Timeline
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/form-wizard.html">
                          Form Wizard
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <a type="button">
                      <i data-feather="bar-chart-2" />
                      <span>Charts</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/chart-apex.html">
                          Apex Charts
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/chart-js.html">
                          Chart Js
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/chart-morris.html">
                          Morris Charts
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/chart-flot.html">
                          Flot Charts
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/chart-peity.html">
                          Peity Charts
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <a type="button">
                      <i data-feather="database" />
                      <span>Icons</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/icon-fontawesome.html">
                          Fontawesome Icons
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/icon-feather.html">
                          Feather Icons
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/icon-ionic.html">
                          Ionic Icons
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/icon-material.html">
                          Material Icons
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/icon-pe7.html">
                          Pe7 Icons
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/icon-simpleline.html">
                          Simpleline Icons
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/icon-themify.html">
                          Themify Icons
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/icon-weather.html">
                          Weather Icons
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/icon-typicon.html">
                          Typicon Icons
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/icon-flag.html">
                          Flag Icons
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <a type="button">
                      <i data-feather="edit" />
                      <span>Forms</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/form-basic-inputs.html">
                          Basic Inputs{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/form-input-groups.html">
                          Input Groups{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/form-horizontal.html">
                          Horizontal Form{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/form-vertical.html">
                          {" "}
                          Vertical Form{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/form-mask.html">
                          Form Mask{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/form-validation.html">
                          Form Validation{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/form-select2.html">
                          Form Select2{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/form-fileupload.html">
                          File Upload{" "}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <a type="button">
                      <i data-feather="columns" />
                      <span>Tables</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/tables-basic.html">
                          Basic Tables{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/data-tables.html">
                          Data Table{" "}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="submenu-open">
                <h6 className="submenu-hdr">Settings</h6>
                <ul>
                  <li className="submenu">
                    <a type="button">
                      <i data-feather="settings" />
                      <span>Settings</span>
                      <span className="menu-arrow" />
                    </a>
                    <ul>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/generalsettings.html">
                          General Settings
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/emailsettings.html">
                          Email Settings
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/paymentsettings.html">
                          Payment Settings
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/currencysettings.html">
                          Currency Settings
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/grouppermissions.html">
                          Group Permissions
                        </a>
                      </li>
                      <li>
                        <a href="https://dreamspos.dreamstechnologies.com/html/template/taxrates.html">
                          Tax Rates
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="https://dreamspos.dreamstechnologies.com/html/template/signin.html">
                      <i data-feather="log-out" />
                      <span>Logout</span>{" "}
                    </a>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      {/* /Sidebar */}
    </>
  );
};

export default Sidebar;
